<template>
  <div class="_modal" v-show="show">
    <div class="_modal-content">
        <Loading v-if="showLoading"></Loading>
        <Modal v-if="showModal" :showTitle="false" :modal="message"></Modal>
        <Alert v-if="showAlert" :modal="message"></Alert>
    </div>
  </div>
</template>

<script>
import { closeMessage } from '@/js/app/messages.js'

import Loading from '@/components/messages/Loading'
import Modal from '@/components/messages/Modal'
import Alert from '@/components/messages/Alert'

export default {
  name: "Message",
  props: {},
  components: {
    Loading, Modal, Alert
  },
  computed: {
    show() {
      return this.message.show;
    },
    showLoading() {
        return this.message.mode === 'L' ? true : false
    },
    showModal() {
        return this.message.mode === 'M' ? true : false
    },
    showAlert() {
        return this.message.mode === 'A' ? true : false
    },
    message() {
      return this.$store.state.message;
    }
  },
  data() {
    return {};
  },
  methods: {
    closeMessage,
    close() {
      this.closeMessage()
      this.$emit("close-message", false) 
    }
  }, 
  watch: {
    message: {
      immediate: true,
      handler() {
        //console.log('Atualizou a message', message)
      }
    }

  }
};
</script>

<style>

</style>