export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/home',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Sistema',
        route: '/',
        icon: 'cil-puzzle',
        items: [
          {
            name: 'Tenant',
            to: '/tenant'
          },
          {
            name: 'Parâmetros Gerais',
            to: '/general-parameter'
          },
          {
            name: 'Domínios do Sistema',
            to: '/domain'
          },
          {
            name: 'Produtos',
            to: '/product'
          },
          {
            name: 'Componentes',
            to: '/component'
          },
          {
            name: 'Trail',
            to: '/trail'
          },
          {
            name: 'Equipamentos',
            to: '/equipment'
          },
          {
            name: 'Macros',
            to: '/macro'
          },
          {
            name: 'Certificadoras',
            to: '/seal'
          },
          {
            name: 'Software',
            to: '/software'
          }

        ]
      },      
      /*
      {
        _name: 'CSidebarNavDropdown',
        name: 'Plano de Execução',
        route: '/',
        icon: 'cil-puzzle',
        items: []
      }
      */      
    ]
  }
]