<template>
  <div class="c-app">
    <Message /> 
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter/>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import Message from '@/components/Message'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    Message
  },
  computed: {
    userAuth() {
      return this.$store.state.userAuth;
    }
  },
  methods: {
    validateUser() {
      if(Object.entries(this.userAuth).length === 0) {
        this.$router.push({ name: "Signin" });
      }
    }
  },
  created() {
    this.validateUser();
  },
  beforeDestroy() {}
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
