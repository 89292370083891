<template>
  <div class="_loading">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <h4>AGUARDE</h4>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {},
  components: {},
  computed: {},
  data() {
    return {};
  },
};
</script>