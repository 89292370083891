<template>
  <CFooter :fixed="true">
    <div>
      <span>DIGI MANAGER</span>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">DIGISYSTEM | PRODUTOS & SOLUÇÕES</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
