<template>
  <!-- Modal Component -->
  <CModal :show.sync="modal.show" :centered="true" :scrollable="true" @update:show="close()">
    <div class="_modal-container">    
      <div class="_modal-message-header">
        <!-- MESSAGE -->
        <div class="_modal-message">
          <!-- Icone -->
          <div class="sa">
            <!-- SUCCESS -->
            <div class="sa-success" v-show="showSuccess">
              <div class="sa-success-tip"></div>
              <div class="sa-success-long"></div>
              <div class="sa-success-placeholder"></div>
              <div class="sa-success-fix"></div>
              <div class="sa-success-pulse"></div>
            </div>

            <!-- WARNING -->
            <div class="sa-warning" v-show="showWarning">
              <div class="sa-warning-body"></div>
              <div class="sa-warning-dot"></div>
            </div>

            <!-- ERROR -->
            <div class="sa-error" v-show="showError">
              <div class="sa-error-x">
                <div class="sa-error-left"></div>
                <div class="sa-error-right"></div>
              </div>
              <div class="sa-error-placeholder"></div>
              <div class="sa-error-fix"></div>
            </div>
          </div>
        </div>
        
        <!-- Title -->
        <div class="_modal-message-title my-3">
          <CRow >
            <CCol sm="12">
              <h4 class="pb-3">{{modal.title}}</h4>
            </CCol>
          </CRow>
        </div>
        <!-- Subtitle -->
        <div class="_modal-message-title mb-3 text-center">
          <CCol sm="12">
            <h6>{{modal.subtitle}}</h6>
          </CCol>
        </div>
        <div class="_modal-container-body" v-if="modal.error.length > 0">
          <transition name="fade">
            <CCard class="_card">
              <CCardHeader class="_card-header-group" @click="isCollapsed = !isCollapsed">
                Detalhes
                <div class="card-header-actions">
                  <CLink class="card-header-action btn-minimize">
                    <CIcon :name="`cil-chevron-${isCollapsed ? 'top' : 'bottom'}`"/>
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="isCollapsed" :duration="400">
                <CRow>
                  <CCol sm="12">
                    <div v-for="(item, index) in modal.error" :key="index">
                      <i class="fas fa-angle-right mx-2"></i>
                      <span>{{ item }}</span>
                    </div>       
                  </CCol>
                </CRow>
              </CCollapse>
            </CCard>
          </transition>
        </div>
      </div>
    </div>
    <template #footer>
      <CButton @click="close" color="blue-dark">OK</CButton>
    </template>
  </CModal>
</template>

<script>
import { closeMessage } from "@/js/app/messages.js";

export default {
  name: "Modal",
  props: {
    modal: Object
  },
  components: {},
  computed: {
    showSuccess() {
      return this.modal.type === 'success' ? true : false
    },
    showWarning() {
      return this.modal.type === 'warning' ? true : false
    },
    showError() {
      return this.modal.type === 'error' ? true : false
    },
  },
  data() {
    return {
      isCollapsed: this.modal.isCollapsed,
      iconCollapsed: null,
    };
  },
  methods: {
    closeMessage,
    close() {
      this.closeMessage()      
    }
  }
};
</script>